const parent = 'setmall';
const _name = { parent, name: 'name' };

export default [
    // 设置商场信息
    // {
    //     path: "/setmall",
    //     name: "setmall",
    //     meta: _name,
    //     component: () => import("@/components/Setmall/Setmall"),
    // },
    // {
    //     path: "/setmalldetail",
    //     name: "setmalldetail",
    //     meta: { parent, name: 'setmall' },
    //     component: () => import("@/components/Setmall/SetMallDetail"),
    // },
    {
        path: "/setmall",
        name: "setmall",
        meta: _name,
        component: () => import("@/views/SetMall"),
    },
    {
        path: "/setmalldetail",
        name: "setmalldetail",
        meta: { parent, name: 'setmall' },
        component: () => import("@/views/SetMall/edit"),
    },
    {
        path: "/setmallreception",
        name: "setmallreception",
        meta: _name,
        component: () => import("@/views/SetMallReception"),
    },
    {
        path: "/setmallreceptionedit",
        name: "setmallreceptionedit",
        meta: { parent, name: 'setmallreception' },
        component: () => import("@/views/SetMallReception/edit"),
    },
    {
        path: "/newshopset",
        name: "newshopset",
        meta: _name,
        component: () => import("@/components/Config/NewShopSet"),
    },
    {
        path: "/shopManage",
        name: "shopManage",
        meta: _name,
        component: () => import("@/views/ShopManage"),
    },
    // 行业管理
    {
        path: "/industryManagement",
        name: "industryManagement",
        meta: { ..._name, title: '行业管理' },
        component: () => import("@/views/industryManagement/index"),
    },
]